const axios = require('axios')

let url = process.env.API_URL
let headers = {
    "Content-Type": "application/json",
};
const instance = axios.create({
    baseURL: url,
    headers: headers
});

instance.interceptors.request.use(config => {
    config.headers.authorization = 'Bearer ' + localStorage.getItem('clients_jwt')
    return config;
});

export { instance as Axios }
