<template>
  <div class="flex justify-between pr-2">
    <div class="md:bg-primary w-64">
      <div class="h-16 px-8 flex items-center" v-if="clientHmo">
        <div class="hmo-logo-div" v-if="clientHmo.logo">
          <img :src="clientHmo.logo" alt="logo"/>
        </div>
        <h1 class="text-lg font-bold md:text-white">
          {{ clientHmo.name.slice(0, 12) }}
        </h1>
      </div>
    </div>
    <div class="flex space-x-3">
      <button>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-notification"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#000000"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M10 6h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"
          />
          <circle cx="17" cy="7" r="3" />
        </svg>
      </button>
      <div class="flex items-center justify-center relative cursor-pointer space-x-3">
        <div class="flex items-center justify-center pr-1 focus:outline-none" @click="dropdownHandler($event, 1)">
          <ul
            class="
              p-2
              border-r
              bg-white
              absolute
              rounded
              z-40
              shadow
              hidden
              mt-3
              top-12
            "
          >
          <router-link class="no-underline" to="/client/settings/profile">
            <li class="cursor-pointer text-gray-600
                dark:text-gray-400 text-sm leading-3
                tracking-normal py-2
                hover:text-indigo-700
                focus:text-indigo-700
                focus:outline-none">
              <div class="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-user"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <circle cx="12" cy="7" r="4" />
                  <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>
                <span class="ml-2">My Profile</span>
              </div>
            </li>
          </router-link>
            <!-- <li
              class="
                cursor-pointer
                text-gray-600
                dark:text-gray-400
                text-sm
                leading-3
                tracking-normal
                mt-2
                py-2
                hover:text-indigo-700
                focus:text-indigo-700
                focus:outline-none
                flex
                items-center
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-help"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <circle cx="12" cy="12" r="9" />
                <line x1="12" y1="17" x2="12" y2="17.01" />
                <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
              </svg>
              <span class="ml-2">Help Center</span>
            </li> -->
            <!-- <router-link class="no-underline" to="/client/settings/password">
              <li
                class="
                  cursor-pointer
                  text-gray-600
                  dark:text-gray-400
                  text-sm
                  leading-3
                  tracking-normal
                  mt-2
                  py-2
                  hover:text-indigo-700
                  flex
                  items-center
                  focus:text-indigo-700
                  focus:outline-none
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-key"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#000000"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="8" cy="15" r="4" />
                  <line x1="10.85" y1="12.15" x2="19" y2="4" />
                  <line x1="18" y1="5" x2="20" y2="7" />
                  <line x1="15" y1="8" x2="17" y2="10" />
                </svg>
                <span class="ml-2">Change Password</span>
              </li>
            </router-link> -->

            <li
              class="
                cursor-pointer
                text-gray-600
                dark:text-gray-400
                text-sm
                leading-3
                tracking-normal
                mt-2
                py-2
                hover:text-indigo-700
                flex
                items-center
                focus:text-indigo-700
                focus:outline-none
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-logout"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"
                ></path>
                <path d="M7 12h14l-3 -3m0 6l3 -3"></path>
              </svg>
              <span class="ml-2" @click="signOut">Sign Out</span>
            </li>
          </ul>
          <div class="w-10 h-10 bg-cover rounded mr-3">
            <img
              :src="logoSrc"
              class="rounded h-full w-full overflow-hidden shadow"
              alt=""
            />
          </div>
          <div class="flex items-center">
            <p class="text-black text-base font-medium">
              {{ username }}
            </p>
            <div class="ml-3 cursor-pointer text-gray-600 dark:text-gray-400">
              <svg
                id="upIcon1"
                xmlns="http://www.w3.org/2000/svg"
                class="hidden icon icon-tabler icon-tabler-chevron-up"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ffffff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <polyline points="6 15 12 9 18 15" />
              </svg>
              <svg
                id="downIcon1"
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-chevron-down"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#000000"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <polyline points="6 9 12 15 18 9" />
              </svg>
            </div>
          </div>
        </div>
        <button
          @click="openMenu"
          class=" focus:outline-none md:hidden"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-menu-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#000000"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="4" y1="6" x2="20" y2="6" />
            <line x1="4" y1="12" x2="20" y2="12" />
            <line x1="4" y1="18" x2="20" y2="18" />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="relative min-h-screen md:flex w-full">
    <!-- sidebar -->
    <div
      ref="sidey"
      style="z-index: 999"
      class="
        w-64
        bg-primary
        space-y-6
        absolute
        md:relative
        md:translate-x-0
        inset-y-0
        left-0
        transform
        -translate-x-full
        transition
        duration-200
        ease-in-out
      "
    >
      <!-- logo -->
      <nav>
        <ul style="color: #d5d6d7">
          <li
            class="block py-2.5"
            v-for="menuItem in dashboardMenu"
            :key="menuItem.label"
          >
            <router-link
              class="
                flex
                w-full
                space-x-2
                p-6
                cursor-pointer
                items-center
                hover:text-white
                transition
                duration-200
                text-gray-400
                no-underline
              "
              :to="menuItem.route"
            >
              <div class="py-1 px-3 flex items-center justify-center text-lg">
                <em :class="[menuItem.icon]"></em>
              </div>
              <div class="">
                <span class="text-sm">{{ menuItem.label }}</span>
              </div>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
    <!--content-->
    <div class="flex-1 p-10">
      <!-- Navigation ends -->
      <div class="">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import clientService from '../../services/client/client-service';
import defaultImage from '../../assets/user.jpg'

export default {
  name: "Dashboard",
  mounted() {
    this.getClientDetails();
  },
  data() {
    return {
      moved: true,
      username: null,
      logo: null,
      clientHmo: null,
      dashboardMenu: [
        {
          label: "Home",
          route: "/client",
          icon: "fas fa-home",
        },
        {
          label: "Enrollee Lists",
          route: "/client/enrollees",
          icon: "fas fa-th-list",
        },
        {
          label: "Add Enrollee",
          route: "/client/add-enrollee",
          icon: "fas fa-user-plus",
        },
        {
          label: "Import Bulk",
          route: "/client/import-bulk-upload",
          icon: "fas fa-user-friends",
        },
      ],
    };
  },
  computed: {
    logoSrc() {
      return this.logo ? this.logo : defaultImage
    }
  },
  methods: {
    openMenu() {
      let sidebar = this.$refs.sidey;
      sidebar.classList.toggle("-translate-x-full");
    },
    loadOrganizationName() {
      this.username = jwt_decode(localStorage.getItem("clients_jwt"))[
        "user_name"
      ].split("_")[0];
    },
    async getClientDetails() {
      let loader = this.$loading.show()
      try {
        const { data } = await clientService.getClientDetail()
        const { name, logo, hmo } = data.data
        this.username = name
        this.logo = logo
        this.clientHmo = hmo
      } catch(error) {
        console.error(error)
      } finally {
        loader.hide()
      }
    },
    dropdownHandler(event) {
      let single = event.currentTarget.getElementsByTagName("ul")[0];
      single.classList.toggle("hidden");
    },
    sidebarHandler() {
      var sideBar = document.getElementById("mobile-nav");
      sideBar.style.transform = "translateX(-260px)";
      if (this.$data.moved) {
        sideBar.style.transform = "translateX(0px)";
        this.$data.moved = false;
      } else {
        sideBar.style.transform = "translateX(-260px)";
        this.$data.moved = true;
      }
    },
    signOut() {
      let loader = this.$loading.show();
      localStorage.removeItem("clients_jwt");
      this.$router.replace("/login");
      loader.hide();
    },
  },
};
</script>

<style scoped>
.router-link-active {
  color: white;
  border-left: 4px solid white;
  @apply bg-white bg-opacity-25;
}
.hmo-logo-div {
  width: 40px;
  height: 40px;
  margin: 0px 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>