import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueSweetalert2 from 'vue-sweetalert2';
import "vue-multiselect/dist/vue-multiselect.css";
import 'sweetalert2/dist/sweetalert2.min.css';

const sweetAlertOptions = {
    confirmButtonColor: '#114C70',
    cancelButtonColor: '#FF4B5C',
};

const app = createApp(App);
app.config.globalProperties.$filters = {
    toCurrency: function toCurrrency(amount, currency = "NGN", languageCode = "en-NG") {
        return new Intl
            .NumberFormat(languageCode, {style: 'currency', currency: currency})
            .format(amount);
    },

    toNumber: function toNumber(amount) {
        return new Intl.NumberFormat().format(amount);
    }
};

app.use(store)
    .use(router)
    .use(Loading, {
        color: '#252733',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 999
    });
app.use(VueSweetalert2, sweetAlertOptions)

app.mount("#app");


