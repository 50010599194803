import {
    Axios
} from "@/shared/axios"

/**
 * A Service to handle API calls for Dashboard page.
 * @class
 */
export default class DashboardService {
   

    constructor() {

    }

    /**
     * Retrieves Dashboard Data
     *
     * 
     * @return Array of summary of Client entities
     *
     * @example
     *
     *     await getDashboardData()
     */
    getDashboardData = async () => {
        try {
            let res = await Axios.get('hmo-client/dashboard')
            return res.data;
        } catch (error) {
            return {
                message: "Error Occured",
                status: 500
            }
        }
    }
}