import { Axios } from "@/shared/axios"

const userService = {
    login: async (loginCredentials) => {
        try {
            let auth = await Axios.post("users/login", loginCredentials.value)
            return auth;
        } catch (error) {
            return {
                message: "Wrong Credentials",
                status: 401
            }
        }
    },

    validateResetEmail: async (data) => {
        try {
            let message = await Axios.post("users/password-reset", data)
            return message.data;
        } catch(error) {
            return {
                message: "Invalid Email",
                status: 401
            }
        }
    },

    resetPassword: async (data) => {
        return Axios.put("users/change-password", data);
    }
};

export default userService;