import Swal from "sweetalert2";

let SuccessToast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    padding: '0 10px',
    iconColor: "#fff",
    timerProgressBar: true,
    background: "#0d360a",
    icon: 'success',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let ErrorToast = Swal.mixin({
    toast: true,
    icon: 'error',
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    padding: '0 10px',
    iconColor: "#fff",
    timerProgressBar: true,
    background: "#7d0f0f",
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export {ErrorToast, SuccessToast}