export default [
    
    { 
        path: "",
        name: "dashboard",
        meta: { requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "../app/dashboard/DashboardComponent"),
    },
    {
        path: "enrollees",
        name: "enrollees",
        meta: { requiresAuth: true },
        component: () =>
            import(/* webpackChunkName: "app.enrollees-list" */ "../app/enrollee/List.vue"),
    },
    {
        path: "enrollees/:id",
        name: "enrollee-detail",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "app.enrollee-detail" */ "../app/enrollee/component/active-enrollee-details")
    },
    {
        path: "add-enrollee",
        name: "add-enrollee",
        meta: { requiresAuth: true },
        component: () =>
            import(/* webpackChunkName: "add-enrollee" */ "../app/enrollee/AddNew.vue")
    },
    {
        path: "edit-enrollee/:id",
        name: "edit-enrollee",
        meta: { requiresAuth: true },
        component: () =>
            import(/* webpackChunkName: "edit-enrollee" */ "../app/enrollee/EditNew.vue")
    },
    // {
    //     path: "add-new-enrollee",
    //     name: "add-new-enrollee",
    //     meta: { requiresAuth: true },
    //     component: () =>
    //         import(/* webpackChunkName: "add-new-enrollee" */ "../app/enrollee/AddNew.vue")
    // },
    // {
    //     path: "edit-new-enrollee/:id",
    //     name: "edit-new-enrollee",
    //     meta: { requiresAuth: true },
    //     component: () =>
    //         import(/* webpackChunkName: "edit-new-enrollee" */ "../app/enrollee/EditNew.vue")
    // },
    {
        path: "settings/password",
        name: "password",
        meta: { requiresAuth: true },
        component: () =>
            import(/* webpackChunkName: "password" */ "../app/settings/password/Password.vue")
    },
    {
        path: "settings/profile",
        name: "profile",
        meta: { requiresAuth: true },
        component: () => 
            import(/* webpackChunkName: "profile" */ "../app/settings/profile/Profile.vue")
    },
    {
        path: "import-bulk-upload",
        name: "import-bulk-upload",
        meta: { requiresAuth: true },
        component: () =>
            import(/* webpackChunkName: "import-bulk-upload" */ "../app/enrollee/bulk-upload/ImportBulk"),
    },
]