import { Axios } from "@/shared/axios"

const clientService = {
    getClientDetail: async () => {
        try {
            return await Axios.get('hmo-client/details')
        } catch (error) {
            return {
                message: "Error fetching details. Try again.",
                status: 500
            }
        }
    },

    updateClientDetail: async data => {
        try {
            return await Axios.put('hmo-client/details', data)
        } catch (error) {
            return error
        }
    }
}

export default clientService;