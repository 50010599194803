import DashboardService from "../services/dashboard/dashboard-service"

const defaults = {
    hmo_plans_summary: [],
  }

  // Dashboard module
  export default {
    namespaced: true,
   
    state: Object.assign({}, defaults),
    
    getters:{
      plansSummary(state){
          return state.hmo_plans_summary;
      }
    },
    mutations: {
    
      updatePlansSummary(state,options){

         state.hmo_plans_summary = options
      }
    },
  
    actions: {
      async fetchPlansSummary ({ commit }) {
          
         let dashboardService = new DashboardService();
         let data = await dashboardService.getDashboardData();
         commit("updatePlansSummary",data.hmo_plans_summary);
        
      }
    }
  }