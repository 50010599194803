import { createRouter, createWebHashHistory } from "vue-router";
import Login3 from "@/app/auth/Login3";
import ForgotPassword from "@/app/auth/ForgotPassword";
import ResetPassword from "@/app/auth/ResetPassword";
import Dashboard from "@/app/dashboard/Dashboard";
import children from "./children";
import { ErrorToast } from "@/shared/mixins/Toast";
import { isTokenExpired } from "@/shared/helpers";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Login3,
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: ForgotPassword
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: ResetPassword,
        beforeEnter: (to, from, next) => {
            let token = to.params.token;
            next();
        }
    },
    {
        path: "/client",
        name: "client",
        component: Dashboard,
        meta: { requiresAuth: true },
        children
    },
    { path: "/:pathMatch(.*)*", redirect: "/" }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        const token = localStorage.getItem('clients_jwt');
        if (token && isTokenExpired(token)) {
            ErrorToast.fire({ title: `<span style="color:#fff">Token expired, Please Login</span>` });
            next({ path: '/', query: { redirect: to.fullPath } })
        } else if (!token) {
            ErrorToast.fire({ title: `<span style="color:#fff">Please Login</span>` })
            next({ path: '/', query: { redirect: to.fullPath } })
        } else {
            next()
        }
    } else {
        // allow calls to routes that don't require auth
        next()
    }
})

export default router;
