import Swal from 'sweetalert2'
import jwt_decode from "jwt-decode";


function getInitials (name) {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...name.matchAll(rgx)] || [];

    return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
}

const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000,
    //timerProgressBar:true
});

let showError = function (title,err,use_toast=false) {
    let msg = "";
    if(err) {
        if (err.errors)
            msg = err.errors;
        else if (err.data)
            msg = err.data;
        else
            msg = err.message || err;
    }

    if((msg.constructor===Array) || msg.constructor===Object) msg = Object.values(msg).join("<br/>")

    return use_toast? toast(title,msg,'error') : new Swal(title,msg,'error')
}

let showSuccess = function (title,message, use_toast = false) {
    if(message.message) message = message.message;
    if(message.data && message.data.message) message = message.data.message;

    if((message.constructor===Array) || message.constructor===Object) message = Object.values(message).join("<br/>")
    return use_toast? toast(title,message,'success') : new Swal(title,message,'success',true)
}

function getTokenExpirationDate(token) {
    let decoded = jwt_decode(token);
    if(typeof decoded.exp === "undefined") {
        return null;
    }

    let d = new Date(0); // The 0 here is the key, which sets the date to the epoch
    d.setUTCSeconds(decoded.exp);

    return d;
}

function isTokenExpired(token) {
    const d = getTokenExpirationDate(token)
    if (d === null) {
        return false;
    }

    // Token expired?
    return !(d.valueOf() > (new Date().valueOf() + (0)));
}




export {
    showError,
    showSuccess,
    toast,
    getInitials,
    isTokenExpired
}